import { format } from "quasar";
const { capitalize } = format;

export function useInternalLink() {
  const getInternalLinkData = (link, sublink = null) => {
    if (!link) {
      return { slug: null, title: null, toRoute: null };
    }

    const linkRef = sublink ? sublink.reference : link.reference;

    let slug, title, toRoute;
    if (linkRef?._type)
      switch (linkRef._type) {
        case "article":
          slug = linkRef.slug?.current;
          title = linkRef.title;
          toRoute = {
            name: linkRef._type,
            params: { blog: linkRef.category?.slug?.current, article: slug },
          };
          break;
        case "blog":
        case "cart":
          slug = linkRef._type;
          title = capitalize(linkRef._type);
          toRoute = { name: linkRef._type };
          break;
        case "category":
          slug = linkRef.slug?.current;
          title = linkRef.title;
          toRoute = { name: linkRef._type, params: { category: slug } };
          break;
        case "collection":
          slug = linkRef.store?.slug?.current;
          title = linkRef.store?.title;
          if (sublink) {
            toRoute = {
              name: "landingCollection",
              params: {
                landing: link.reference?.slug?.current,
                collection: slug,
              },
            };
          } else {
            toRoute = { name: linkRef._type, params: { collection: slug } };
          }
          break;
        case "landing":
          slug = linkRef.slug?.current;
          title = linkRef.title;
          toRoute = { name: linkRef._type, params: { landing: slug } };
          break;
        case "product":
          slug = linkRef.store?.slug?.current;
          title = linkRef.store?.title;
          if (sublink) {
            toRoute = {
              name: "landingProduct",
              params: {
                landing: link.reference?.slug?.current,
                product: slug,
              },
            };
          } else {
            toRoute = { name: linkRef._type, params: { product: slug } };
          }
          break;
        case "search":
          slug = linkRef._type;
          title = capitalize(linkRef._type);
          toRoute = { name: linkRef._type };
          break;
        default:
          slug = linkRef.slug?.current;
          title = linkRef.title;
          toRoute = { name: linkRef._type, params: { page: slug } };
          break;
      }
    return { slug, title, toRoute };
  };

  return { getInternalLinkData };
}
