<template>
  <div class="atropos my-atropos overflow-visible" ref="atropos">
    <div class="atropos-scale">
      <div class="atropos-rotate">
        <div class="atropos-inner">
          <div @mouseenter="onEnter()" @mouseleave="onLeave()">
            <product-variant :product="data">
              <template #default="{ variants }">
                <div
                  v-intersection="options"
                  class="full-height relative-position"
                >
                  <div v-if="data.images?.edges?.length" class="fit">
                    <router-link
                      :to="toRoute(data, $route)"
                      class="absolute-full"
                    >
                      <product-image
                        :image="data.images.edges[0]"
                        :ratio="ratio"
                        @color-value="(color) => onColorValue(color)"
                      />
                      <div class="absolute-full">
                        <swiper-component
                          v-if="firstHover && data.images.edges.length > 2"
                          :autoplay="{
                            delay: 800,
                            disableOnInteraction: false,
                            pauseOnMouseEnter: false,
                          }"
                          hoverable
                          :hovering="hover"
                          :navigation="false"
                          :slides="data.images.edges"
                          @color-value="(color) => onColorValue(color)"
                          @slide-change="(slide) => onSlideChange(slide)"
                        />
                      </div>
                    </router-link>
                  </div>
                </div>
                <content-card-title
                  :classes="[titleClass]"
                  class="absolute-top-left"
                  data-atropos-offset="2"
                  margin-left
                  :padding="padding"
                  style="width: 67%"
                  :title="data.title"
                  :to="toRoute(data, $route)"
                />
                <div class="absolute-top-right" style="z-index: 1">
                  <product-preview-btn
                    :color="textColor"
                    :hover="productPreviewHover"
                    :product="data"
                    style="z-index: 0"
                  />
                </div>
                <content-card-cta
                  after-content
                  :can-emit="canEmit"
                  data-atropos-offset="2"
                  class="absolute-bottom-right"
                  :classes="[titleClass]"
                  :cta="false"
                  margin-right
                  :padding="padding"
                  :to="toRoute(data, $route)"
                  :up="up"
                >
                  <template #default="{}">
                    <variant-price
                      :abbreviated="
                        ($q.screen.lt.md && gridSize < 12) ||
                        (view === 'drawer' &&
                          gridSizeDrawer <= 6 &&
                          $route.name !== 'cart')
                      "
                      class="q-pr-xs"
                      :class="variantPriceClasses"
                      :key="data.handle"
                      :tags="data.tags"
                      :variant="variants[0]"
                    />
                  </template>
                </content-card-cta>
              </template>
            </product-variant>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed, nextTick, onMounted, ref } from "vue";
import { storeToRefs } from "pinia";
import { debounce, Screen, useQuasar } from "quasar";
import { i18n } from "src/boot/i18n";
import { getAverageColor } from "src/composables/image/useImageColor";
import { useIntersection } from "src/composables/intersection/useIntersection";
import { useLayoutStore } from "src/stores/layout";
import ContentCardCta from "src/components/content/ContentCardCta.vue";
import ContentCardTitle from "src/components/content/ContentCardTitle.vue";
import ProductImage from "./ProductImage.vue";
import ProductPreviewBtn from "./ProductPreviewBtn.vue";
import ProductVariant from "./ProductVariant.vue";
import SwiperComponent from "src/components/swiper/SwiperComponent.vue";
import VariantPrice from "./variant/VariantPrice.vue";
import Atropos from "atropos";
import "atropos/css";

const HOVER_DELAY = 1000;
const LEAVE_DELAY = 2000;

defineOptions({ name: "ProductCard" });

const emits = defineEmits(["slideChange"]);

const props = defineProps({
  canEmit: { type: Boolean, default: true },
  data: { type: Object, default: () => {} },
  height: { type: [Boolean, Object], default: false },
  padding: { type: Boolean, default: false },
  ratio: { type: Number, default: 0.6667 },
  root: { type: [Boolean, Object], default: false },
  up: { type: Boolean, default: true },
  view: { type: String, default: "default" },
});

const $q = useQuasar();
const { t } = i18n.global;
const { options, isIntersecting } = useIntersection(95);
const layoutStore = useLayoutStore();
const { gridSize, gridSizeDrawer } = storeToRefs(layoutStore);
const firstHover = ref(false);
const hover = ref(false);
const hoverTimeout = ref(null);
const preview = ref(false);
const textColor = ref("dark");
const atropos = ref(null);

const productPreviewHover = computed(
  () => preview.value || ($q.platform.is.mobile && isIntersecting.value)
);

const titleClass = computed(() =>
  gridSize.value > 6 ? "text-caption" : "text-small"
);

const variantPriceClasses = computed(() =>
  Screen.lt.md && gridSize.value < 12 ? "q-ma-sm" : "q-mb-xs q-mr-sm"
);

const handleColor = (color) => {
  textColor.value = color === "dark" ? "dark" : "secondary";
};

const onColorValue = (color) => {
  handleColor(color);
};

const onEnter = () => {
  if ($q.platform.is.mobile) return;
  preview.value = true;
  hoverTimeout.value = setTimeout(() => {
    if (!firstHover.value) firstHover.value = true;
    hover.value = true;
  }, HOVER_DELAY);
};

const onLeave = debounce(() => {
  if ($q.platform.is.mobile) return;
  preview.value = false;
  clearTimeout(hoverTimeout.value);
  hover.value = false;
}, LEAVE_DELAY);

const toRoute = (product, route) => {
  const routeProductName = `${route.name}Product`;
  let to = {};
  switch (route.name) {
    case "cart":
    case "cartProduct":
      to = {
        name: route.name === "cart" ? routeProductName : route.name,
        params: {
          product: product.handle,
        },
      };
      break;
    case "collection":
    case "collectionProduct":
      to = {
        name: route.name === "collection" ? routeProductName : route.name,
        params: {
          collection: route.params.collection,
          product: product.handle,
        },
        query: { ...route.query },
      };
      break;
    case "landingCollection":
    case "landingCollectionProduct":
      to = {
        name:
          route.name === "landingCollection" ? routeProductName : route.name,
        params: {
          landing: route.params.landing,
          collection: route.params.collection,
          product: product.handle,
        },
        query: { ...route.query },
      };
      break;
    case "search":
    case "searchProduct":
    case "searchPredictProduct":
      to = {
        name: route.name === "search" ? routeProductName : route.name,
        params: {
          product: product.handle,
        },
        query: { term: route.query.term },
      };
      break;
    default:
      to = {
        name: "collectionProduct",
        params: {
          collection: "new-arrivals",
          product: product.handle,
        },
        query: { ...route.query },
      };
      break;
  }
  return to;
};

const onSlideChange = (slide) => {
  emits("slideChange", slide);
  getAverageColor(slide.node.aura, (color) => {
    handleColor(color);
  });
};

onMounted(async () => {
  if ($q.platform.is.mobile) return;
  await nextTick();
  const myAtropos = Atropos({
    el: atropos.value,
    rotateTouch: false,
    rotateXMax: 2.5,
    rotateXMax: 2.5,
  });
});
</script>
